<div class="uploadFileModal">
    <div class="modal-body">
        <div>
            <span class="d-block mb-2">{{ 'itemDocument.uploadFile' | translate }}</span>
            <span class="alert alert-danger d-block w-100 m-0 mb-3" role="alert"
                  *ngIf="errorMessage">{{errorMessage | translate}}</span>
            <span class="alert alert-warning d-block w-100 m-0 mb-3" role="alert"
                  *ngIf="isMaliciousFile && errorMessage === null">{{'errorMessages.maliciousFileType.body' | translate}}</span>
            <div ng2FileDrop
                 [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
                 (fileOver)="fileOver($event)"
                 (onFileDrop)="fileDropped($event)"
                 [uploader]="uploader"
                 class="file-drop-zone d-flex align-items-center justify-content-center my-3">
                <span *ngIf="!isUploading">{{ getUploaderText() }}</span>
                <span *ngIf="isUploading"><i
                    class="fa fa-spinner fa-spin"></i>{{ 'incident.uploadingFile' | translate }}</span>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" [disabled]="isUploading" (click)="close()">{{
            'common.cancel' | translate }}
        </button>
        <label *ngIf="!fileWithNameExists" class="btn btn-primary" [ngClass]="{'disabled': isUploading}">
            {{ 'itemDocument.selectFile' | translate }}
            <input type="file" ng2FileSelect
                   [disabled]="isUploading"
                   [uploader]="uploader"
                   (change)="fileSelected($event)"
                   class="d-none"/>
        </label>
        <button id="uploadAttachmentButton" type="button"
                class="btn btn-secondary"
                [disabled]="isUploading"
                *ngIf="isMaliciousFile && ! fileWithNameExists"
                (click)="uploadFile(false)">{{'incident.btnUploadAttachment' |
            translate}}
        </button>
        <button id="replaceExistingAttachmentButton" type="button"
                class="btn btn-secondary"
                [disabled]="isUploading"
                *ngIf="fileWithNameExists"
                (click)="uploadFile(true)">{{'incident.btnReplaceExistingAttachment' |
            translate}}
        </button>
        <button id="saveAsNewAttachmentButton" type="button"
                class="btn btn-secondary"
                [disabled]="isUploading"
                *ngIf="fileWithNameExists"
                (click)="uploadFile(false)">{{'incident.btnSaveAsNewAttachment' | translate}}
        </button>
    </div>
</div>
