import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { Document } from '@app/shared/document.model';
import { TranslateService } from '@ngx-translate/core';
import { DocumentService } from '@app/providers/document.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { documentStatuses } from '@app/app.constants';

type SaveFileCallback = (document: Document, isMaliciousFile: boolean, verifyFileName: boolean) => null;

@Component({
	selector: 'app-upload-incident-file-modal',
	templateUrl: './upload-incident-file-modal.component.html',
	styleUrls: ['./upload-incident-file-modal.component.scss']
})
export class UploadIncidentFileModalComponent implements OnInit, OnDestroy {
	@Input() onAcceptFileCallback: SaveFileCallback;
	// this input will be used to display options for save a new or replace
	@Input() fileWithNameExists: boolean;
	@Input() availableName: string;
	@Input() errorMessage: string = null;
	@Input() subject: Subject<any>;

	uploader: FileUploader = new FileUploader({ url: null });
	hasBaseDropZoneOver: boolean = false;
	reader: FileReader = new FileReader();
	file: File;
	isUploading: boolean = false;
	selectedDocument: Document;
	isMaliciousFile: boolean = false;

	constructor(private documentService: DocumentService,
		public translateService: TranslateService,
		public dynamicDialogRef: DynamicDialogRef,
		public dynamicDialogConfig: DynamicDialogConfig) {
		this.dynamicDialogConfig.header = this.translateService.instant('itemDocument.uploadDocument');
		this.onAcceptFileCallback = this.dynamicDialogConfig.data.onAcceptFileCallback;
		this.fileWithNameExists = this.dynamicDialogConfig.data.fileWithNameExists;
		this.availableName = this.dynamicDialogConfig.data.availableName;
		this.errorMessage = this.dynamicDialogConfig.data.errorMessage;
		this.subject = this.dynamicDialogConfig.data.observable;

		this.subject?.subscribe((statusCheckRes: { documentStatus: number, fileWithNameExists: boolean, errorMessage: string, availableNewFileName: string }) => {
			if (!statusCheckRes) {
				this.reset();
			}

			switch (statusCheckRes.documentStatus) {
				case documentStatuses.exist:
					this.fileWithNameExists = true;
					this.errorMessage = 'common.msgErrorAttachmentExists';
					this.availableName = statusCheckRes.availableNewFileName;
					break;
			}
		});
	}

	ngOnInit() {
		this.reader.onloadend = (e: any) => {
			this.isUploading = true;
			this.selectedDocument = new Document();

			const dataUrl = this.reader.result as string;
			this.selectedDocument.documentContentsAsBase64 = dataUrl.substring(dataUrl.indexOf(',') + 1);
			this.selectedDocument.documentMimeType = dataUrl.split(',')[0].split(':')[1].split(';')[0];
			this.selectedDocument.documentFileName = this.file.name;
			this.selectedDocument.sizeInBytes = this.file.size;

			if (this.onAcceptFileCallback) {
				this.onAcceptFileCallback(this.selectedDocument, this.isMaliciousFile, true);
			}

			this.isUploading = false;
		};
	}

	ngOnDestroy() {
		this.subject?.unsubscribe();
	}

	fileOver(e: boolean): void {
		this.hasBaseDropZoneOver = e;
	}

	fileDropped(e: any): void {
		this.processFile(e[0]);
	}

	fileSelected(e) {
		this.processFile(e.target.files[0]);
		e.target.value = '';
	}

	processFile(file) {
		this.errorMessage = null;
		this.isMaliciousFile = false;
		this.documentService.checkFileSizeAndMaliciousTypes(file, true).then((_) => {
			this.isMaliciousFile = file.isMaliciousFile;
			this.readAsDataURL(file);
		}).catch((err: any) => {
			this.isMaliciousFile = file.isMaliciousFile;
			this.uploader.clearQueue();
			if (err) {
				this.errorMessage = err.message;
			}
		});
	}

	readAsDataURL(file) {
		this.file = file;

		this.reader.readAsDataURL(file);
	}

	getUploaderText() {
		return this.selectedDocument ? this.selectedDocument.documentFileName : this.translateService.instant('incident.dropFile');
	}

	uploadFile(overwrite: boolean) {
		if (!overwrite
		  && this.availableName) {
			this.selectedDocument.documentFileName = this.availableName;
		}

		if (this.onAcceptFileCallback) {
			this.onAcceptFileCallback(this.selectedDocument, false, false);
		}
		else {
			this.close();
		}
	}

	reset() {
		this.errorMessage = null;
		this.isMaliciousFile = false;
		this.file = null;
		this.selectedDocument = null;
	}

	close() {
		this.dynamicDialogRef.close();
	}
}
